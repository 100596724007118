/* eslint-disable react/jsx-indent */
import { useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { PageLayout } from './components/templates/PageLayout';
import { Home } from './Home';
import { b2cPolicies, loginRequest } from './authConfig';

import './index.css';
import "./App.css";

import LoadOverlay from './components/atoms/LoadOverlay';
import RIA from './components/templates/RIA';
import SignUp from './components/templates/SignUp';
import SettingsTemplate from './components/templates/SettingsTemplate';
import Unsubscribe from './components/templates/Unsubscribe';
import { AppDispatch } from './configureStore';
import { SET_LANGUAGE, setIsDark } from './redux/reducer/auth';

const Pages = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/settings" element={<SettingsTemplate />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

const UnauthPages = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/unsubscribe" element={<Unsubscribe />} />
      <Route path="/ria" element={<RIA />} />
      <Route element={<LoadOverlay />} />
    </Routes>
  </BrowserRouter>
);

const App = () => {
  const { instance, inProgress } = useMsal();
  const searchParams = window?.location?.pathname;

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (navigator?.language?.startsWith('es')) dispatch({ type: SET_LANGUAGE, language: 'es' });
  }, []);

  useEffect(() => {
    if (searchParams === '/unsubscribe' || searchParams === '/ria') return () => { };
    const activeAccount = instance.getActiveAccount();

    const callbackId = instance.addEventCallback((event: any) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)
        && event.payload.account
      ) {
        localStorage.setItem("ID_TOKEN", event.payload.idToken);
        if (event.payload.idTokenClaims.tfp === b2cPolicies.names.editProfile) {
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account: any) => account.idTokenClaims.oid === event.payload.idTokenClaims.oid
                && account.idTokenClaims.sub === event.payload.idTokenClaims.sub
                && account.idTokenClaims.tfp === b2cPolicies.names.signUpSignIn,
            );

          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          instance.ssoSilent(signUpSignInFlowRequest);
        }
        if (event.payload.idTokenClaims.tfp === b2cPolicies.names.forgotPassword) {
          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    if (activeAccount && searchParams && searchParams.toLowerCase() === '/signup') {
      window.location.replace(process.env.REACT_APP_BASE_URI ?? '/');
    }

    if (!activeAccount && inProgress === InteractionStatus.None) {
      if (searchParams && searchParams.toLowerCase() === '/signup') {
        const signUpFlowRequest = {
          authority: b2cPolicies.authorities.signUp.authority,
          scopes: [],
        };
        instance.loginRedirect(signUpFlowRequest).catch((error) => console.error(error));
      } else {
        instance.loginRedirect(loginRequest).catch((error) => console.error(error));
      }
    }
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, inProgress]);

  useEffect(() => {
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches;

    if (prefersDark) {
      dispatch(setIsDark(true));
    }
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <Pages />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthPages />
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
