import { Col, Row } from "antd";
import { useSelector } from "react-redux";

import useWindowDimensions from "../../../utils/hooks/useWindowDimensions";
import GoPlanVideo from "../../molecules/GoPlanVideo";
import Text from "../../atoms/Text";
import { RootState } from "../../../configureStore";
import { CustomText } from "../../../utils/config";

interface IntroParams {
}

const Intro = ({
}: IntroParams) => {
    const { isMobile } = useWindowDimensions();
    const marketing = useSelector<RootState, CustomText[]>(({ auth: { configuration: { intro } } }) => intro.marketing);

    return (
        <Row id="intro_message" gutter={24} className="my-8">
            <Col span={isMobile ? 24 : 16}>
                {marketing.map((val, idx) => (<Text key={`mktg-${idx}`} customText={val} className={val.tag === 'h3' ? "my-4" : "my-2"} />))}
            </Col>
            <Col span={isMobile ? 24 : 8}><GoPlanVideo /></Col>
        </Row>
    );
};

export default Intro;
