import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeTemplate from './components/templates/HomeTemplate';
import { RootState } from './configureStore';
import { Me } from './redux/reducer/auth';

export const Home = () => {
    const me = useSelector<RootState, Me>(({ auth }) => auth.me as Me);
    const navigate = useNavigate();

    useEffect(() => {
        if (me && Object.keys(me).length && !me.tpInstitution) navigate('/ria');
    }, [me]);

    return <HomeTemplate />;
};
