import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../atoms/Icon";
import colors from "../../utils/colors";
import { Me } from "../../redux/reducer/auth";
import { RootState } from "../../configureStore";

const routeItems = [
    {
        display: 'Tax Filing',
        pathname: '/',
        icon: "FileDoneOutlined",
        activeIcon: "FileFilled",
    },
    {
        display: 'Advisory',
        pathname: '/ria',
        icon: "CompassOutlined",
        activeIcon: "CompassFilled",
    },
];

const MenuItem = ({
    route,
    active,
}: {
    route: {
        display: string;
        pathname: string;
        icon: string;
        activeIcon?: string;
        onClick?: (items: { instance: IPublicClientApplication }) => void;
    };
    active: boolean;
}) => {
    const navigation = useNavigate();
    const { instance } = useMsal();

    return (
        <div
            className="flex-center-start row mb-4 pl-4 pointer"
            style={{
                height: '60px',
                backgroundColor: active ? colors.lightGreen : undefined,
                borderTopRightRadius: '16px',
                borderBottomRightRadius: '16px',
                borderLeftColor: active ? colors.darkGreen : "transparent",
                borderLeftWidth: '8px',
                borderLeftStyle: "solid",
            }}
            onClick={() => { route.onClick ? route.onClick({ instance }) : navigation(route.pathname); }}
        >
            <div
                className="flex-center-row"
                style={{
                    justifyContent: 'start',
                }}
            >
                <Icon name={active ? route.activeIcon ?? route.icon : route.icon} color={active ? colors.darkGreen : undefined} />
                <p
                    style={{
                        color: active ? colors.darkGreen : undefined,
                        marginLeft: '8px',
                    }}
                >
                    {route.display}
                </p>
            </div>
            <div style={{ width: '32px' }} />
        </div>
    );
};

const AppMenu = () => {
    const location = useLocation();
    const me = useSelector<RootState, Me>(({ auth }) => auth.me as Me);
    const routes = useMemo(() => routeItems.filter(({ pathname }) => (pathname === '/' && me.tpInstitution) || (pathname === '/ria' && me.ria)), [me]);

    return (
        <div className="py-4 pr-4">
            {
                routes.map((route) => (
                    <MenuItem
                        key={route.pathname}
                        route={route}
                        active={location.pathname === route.pathname}
                    />
                ))
            }
        </div>
    );
};

export default AppMenu;
