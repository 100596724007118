import {
    Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../configureStore";
import { Me, acceptTerms } from "../../redux/reducer/auth";
import { TNCType, tncRiaArray } from "../../utils/riaTnc";
import { Configuration } from "../../utils/config";
import Text from "../atoms/Text";

interface AgreeModalParams {
    tnc: boolean;
    setTnc: (_: boolean) => void;
    code: string | null;
    email: string | null;
}

const AgreeModal = ({
    tnc,
    email,
    setTnc,
    code,
}: AgreeModalParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['auth/ACCEPT_TERMS']);
    const config = useSelector<RootState, Configuration>(({ auth: { configuration } }) => configuration);
    const institutionName = useSelector<RootState, string>(({ auth: { me } }) => (me as Me).institutionName);
    const csdeLink = useSelector<RootState, string>(({ auth: { me } }) => (me as Me).csdeLink);
    const isDark = useSelector<RootState, boolean>(({ auth: { isDark: dark } }) => dark);
    const language = useSelector<RootState, 'es' | 'en'>(({ auth: { lng } }) => lng);

    const acceptTnc = () => {
        dispatch(
            acceptTerms({
                emailAddress: email,
                code,
                onSuccess: () => {
                    setTnc(false);
                },
            }),
        );
    };

    return (
        <Modal
            open={tnc}
            closable={false}
            onOk={acceptTnc}
            okButtonProps={{ loading }}
            okText="I Consent"
            onCancel={() => { setTnc(false); }}
            destroyOnClose
            styles={{
                content: { backgroundColor: config.app.backgroundColor ? config.app.backgroundColor : isDark ? '#16161d' : '#ffffff' },
            }}
        >
            <div id="terms-and-conditions">
                {tncRiaArray.map(({ type, text }, idx) => (
                    <Text
                        key={`tnc-${idx}`}
                        tag={type === TNCType.HEADER ? 'h3' : 'p'}
                        defaultText={text}
                        checkReplace={typeof text === 'string' ? text.includes('{{') : text?.[language].includes('{{')}
                        replaceObject={{ institutionName, csdeLink }}
                    />
                ))}
            </div>
        </Modal>
    );
};

export default AgreeModal;
