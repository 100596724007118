import { BrowserCacheLocation, Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";

const B2C_DOMAIN = process.env.REACT_APP_B2C_DOMAIN ?? 'hswtestb2c';
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
const AUTHORITY_BASE = AUTH_DOMAIN ?? `${B2C_DOMAIN}.b2clogin.com`;

export const b2cPolicies = {
    names: {
        signUp: "B2C_1_InvitationCodeFlow",
        signUpSignIn: "B2C_1A_signin",
        editProfile: "B2C_1_edit1",
        forgotPassword: "B2C_1_reset2",
    },
    authorities: {
        signUp: {
            authority: `https://${AUTHORITY_BASE}/${B2C_DOMAIN}.onmicrosoft.com/B2C_1_InvitationCodeFlow`,
            grantType: '',
        },
        signUpSignIn: {
            authority: `https://${AUTHORITY_BASE}/${B2C_DOMAIN}.onmicrosoft.com/B2C_1A_signin`,
            grantType: '',
        },
        editProfile: {
            authority: `https://${AUTHORITY_BASE}/${B2C_DOMAIN}.onmicrosoft.com/B2C_1_edit1`,
            scopes: [],
        },
        forgotPassword: {
            authority: `https://${AUTHORITY_BASE}/${B2C_DOMAIN}.onmicrosoft.com/B2C_1A_PASSWORDRESET`,
            scopes: [],
        },
    },
    authorityDomain: AUTHORITY_BASE,
};

export const protectedResources = {
    todoListApi: {
        endpoint: "http://localhost:3000",
        // scopes: [`https://${B2C_DOMAIN}.onmicrosoft.com/tasks-api/tasks.read`],
    },
};
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_TOKEN_AUDIENCE || 'bd4e8b46-f818-4904-9df1-8e2d50161167', // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_BASE_URI || 'http://localhost:3000', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        // postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        // navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        break;
                    case LogLevel.Info:
                        // if (process.env.NODE_ENV === 'production') console.info(message);
                        break;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        break;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                    default:
                        break;
                }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
    scopes: [],
    prompt: "login",
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: [],
    loginHint: "example@domain.net",
};
