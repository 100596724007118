import { useNavigate } from "react-router-dom";

interface SignUpParams {
}

const SignUp = ({
}: SignUpParams) => {
    const navigate = useNavigate();

    navigate('/');
    return (
        <div />
    );
};

export default SignUp;
