import {
    Layout as AntLayout,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import AppHeader from "../organisms/AppHeader";
import AppMenu from "../organisms/AppMenu";
import { Me, getMe } from "../../redux/reducer/auth";
import { RootState } from "../../configureStore";
import LoadOverlay from "../atoms/LoadOverlay";

const { Header, Content, Sider } = AntLayout;

export const PageLayout = (_: any) => {
    const isMobile = useMemo(() => window.innerWidth < 500, [window]);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['auth/GET_ME']);
    const me = useSelector<RootState, Me>(({ auth }) => auth.me as Me);
    const dispatch = useDispatch<any>();
    useEffect(() => {
        dispatch(getMe());
    }, []);
    const [menuOpen, setMenuOpen] = useState(me ? (me.ria && me.tcId) : false);

    return loading
        ? <LoadOverlay />
        : (
            <AntLayout
                style={{
                    height: '100vh',
                    overflowY: 'auto',
                    padding: '20px',
                }}
            >
                <Header
                    className="ant-layout-header br-md mb-5 white"
                    style={{ height: 'fit-content' }}
                >
                    <AppHeader
                        toggleMenu={() => { setMenuOpen(!menuOpen); }}
                    />
                </Header>
                <AntLayout>
                    {!isMobile && (
                        <Sider
                            collapsed={!menuOpen}
                            collapsedWidth={0}
                            className={`br-md white ${!menuOpen ? '' : 'mr-5'}`}
                        >
                            <AppMenu />
                        </Sider>
                    )}
                    <Content>
                        <Outlet />
                    </Content>
                </AntLayout>
            </AntLayout>
        );
};
