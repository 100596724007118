import { Button, Image } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import { Me } from "../../redux/reducer/auth";

interface HomeTemplateParams {
}

const uri = process.env.REACT_APP_TC_URI ?? process.env.TC_URI ?? "https://hswtestb2c.b2clogin.com/hswtestb2c.onmicrosoft.com/B2C_1A_signup_signin_saml/generic/login?EntityId=https://hswtestb2c.onmicrosoft.com/bd4e8b46-f818-4904-9df1-8e2d50161167&RelayState=https://stg-taxcaddy-apollo-internal.azure-api.net/sso/saml";

const HomeTemplate = ({
}: HomeTemplateParams) => {
    const me = useSelector<RootState, Me>(({ auth }) => auth.me as Me);

    return (
        <div className="flex-center-center col">
            <h1 style={{ fontSize: 32 }} className="my-6">
                Welcome to Halcyon Tax Prep!
            </h1>
            <h3 className="mb-6">
                Hi {me.firstName}, get started filing your taxes below!
            </h3>
            <a href={uri}><Button type="primary">File Taxes</Button></a>
            <Image src="/signup-bg.svg" preview={false} />
        </div>
    );
};

export default HomeTemplate;
