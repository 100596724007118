import { combineReducers } from "redux";

import auth from "./auth";
import notification from "./notification";
import status from "./status";

const rootReducer = combineReducers({
    auth,
    notification,
    status,
});

export default rootReducer;
