export interface ITextLanguage {
    es: string;
    en: string;
}

export type IText = string | ITextLanguage;

export interface CustomText {
    text?: IText;
    fontSize?: number | string;
    color?: string;
    none?: boolean;
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
}

export interface Configuration {
    header: {
        title?: CustomText;
        subtitle?: CustomText;
        image?: string
        noImageTint?: boolean;
        logo?: {
            src: string;
            height?: number;
            width?: number;
        };
    };
    app: {
        primaryColor?: string;
        backgroundColor?: string;
        textColor?: {
            header?: string;
            body?: string;
        };
        color?: string;
    };
    intro: {
        marketing: CustomText[];
    };
}

export const defaultConfig: Configuration = {
    header: {
        title: {
            text: 'GoPlan 101',
        },
        subtitle: {
            text: '',
        },
    },
    app: {},
    intro: {
        marketing: [
            {
                text: {
                    en: 'A Pivot Back to Human Advice!',
                    es: '¡Un giro hacia el asesoramiento humano!',
                },
                tag: 'h3',
            },
            {
                text: {
                    en: 'GoPlan 101 is your personal financial coaching platform. You will connect one-on-one with a coach and he/she will help you understand, and realistically assess, your financial situation.',
                    es: 'GoPlan 101 es su plataforma de asesoramiento financiero personal. Te conectarás individualmente con un coach y él te ayudará a comprender y evaluar de manera realista tu situación financiera.',
                },
            },
            {
                text: {
                    en: 'Your coach will help you put a structure around your finances and you\'ll work together to develop a financial plan that you can live with... and don\'t worry, your coach will guide you every step of the way.',
                    es: 'Tu asesor te ayudará a estructurar tus finanzas y trabajarán juntos para desarrollar un plan financiero con el que puedas vivir... y no te preocupes, tu asesor te guiará en cada paso del camino.',
                },
            },
            {
                text: {
                    en: 'Play the video to learn more about the process or click below to book a free appointment.',
                    es: 'Reproduzca el video para obtener más información sobre el proceso o haga clic a continuación para reservar una cita gratuita.',
                },
            },
        ],
    },
};

export const fillConfig = (config: Configuration): Configuration => ({
    ...defaultConfig,
    ...config,
    app: {
        ...defaultConfig.app,
        ...config.app,
    },
    header: {
        ...defaultConfig.header,
        ...config.header,
        title: {
            ...defaultConfig.header?.title,
            ...config.header?.title,
        },
        subtitle: {
            ...defaultConfig.header?.subtitle,
            ...config.header?.subtitle,
        },
    },
    intro: {
        ...defaultConfig.intro,
        ...config.intro,
    },
});

export const customTextStyle = (ct: CustomText, dC?: string) => ({
    fontSize: ct?.fontSize,
    color: ct?.color ?? dC,
    display: ct?.none ? 'none' : undefined,
});
