interface GoPlanVideoParams {
}

const GoPlanVideo = ({
}: GoPlanVideoParams) => (
    <video
        playsInline
        width="100%"
        muted
        preload="auto"
        controls
        style={{ objectFit: 'cover' }}
        poster="https://goplan101.com/wp-content/uploads/2021/01/Blue-Dynamic-Fitness-YouTube-Thumbnail-2.png"
    >
        <source
            type="video/mp4"
            src={`https://assetssttaxprep${process.env.REACT_APP_ENVIRONMENT}.blob.core.windows.net/hsw-tp-assets-${process.env.REACT_APP_ENVIRONMENT}/Halcyon - Financial Coach.mp4`}
        />
    </video>
);

export default GoPlanVideo;
