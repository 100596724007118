import { MenuOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";
import { Dropdown, MenuProps } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Icon from "../atoms/Icon";
import Profile from "../molecules/Profile";
import { RootState } from "../../configureStore";
import { Me } from "../../redux/reducer/auth";

interface AppHeaderParams {
    toggleMenu: () => void,
}

const AppHeader = ({
    toggleMenu,
}: AppHeaderParams) => {
    const navigate = useNavigate();
    const me = useSelector<RootState, Me>(({ auth }) => auth.me as Me ?? {});
    const isMobile = useMemo(() => window.innerWidth < 500, [window]);
    const { instance } = useMsal();

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className="flex-center row pointer"
                    onClick={() => { navigate('/settings'); }}
                >
                    <Profile
                        size="30px"
                        className="mr-2 br-sm"
                        firstName={me.firstName}
                        lastName={me.lastName}
                        image={me.imageUrl}
                    />
                </div>
            ),
        },
        {
            label: 'Logout',
            key: '4',
            danger: true,
            onClick: () => {
                instance.logoutRedirect();
            },
        },
    ];

    return (
        <div
            className="flex-center-between row"
            style={{ height: '91px' }}
        >
            <div className="flex-center row">
                <img
                    width={isMobile ? 50 : 170}
                    src={isMobile ? "/favicon.svg" : "/wordmark.png"}
                    alt="Halcyon"
                    style={{
                        marginRight: '16px',
                    }}
                />
                {(!isMobile && (me.ria && me.tcId)) && <MenuOutlined onClick={() => toggleMenu()} />}
            </div>
            <div className="flex-center-center row-r">
                <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                >
                    <div className="flex-center row ml-8">
                        <Profile
                            size="50px"
                            className="mr-2 br-sm"
                            firstName={me.firstName}
                            lastName={me.lastName}
                            image={me.imageUrl}
                            noName={isMobile}
                        />
                        {!isMobile && (
                            <Icon
                                name="DownOutlined"
                            />
                        )}
                    </div>
                </Dropdown>
                {/* <Icon
                    name="BellOutlined"
                    badge={5}
                    size="32px"
                    onClick={() => { }}
                /> */}
            </div>
        </div>
    );
};

export default AppHeader;
