import { Col, Form, Row, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Icon from "../atoms/Icon";
import colors from "../../utils/colors";
import { Me, uploadImage } from "../../redux/reducer/auth";
import { RootState } from "../../configureStore";
import Profile from "../molecules/Profile";
import InputField from "../atoms/InputField";

interface SettingsTemplateParams {
}

const SettingsTemplate = ({
}: SettingsTemplateParams) => {
    const dispatch = useDispatch<any>();

    const me = useSelector<RootState, Me>(({ auth }) => auth.me as Me);

    const handleUpload = (e: any) => {
        const data = new FormData();
        data.append('file', e.file);

        dispatch(uploadImage({
            data,
        }));
    };

    return (
        <div className="white br-md pa-4">
            <h3 className="mb-4">
                Personal Information
            </h3>
            <Form initialValues={me}>
                <Row className="mb-4">
                    <Col>
                        <Profile
                            noName
                            firstName={me.firstName}
                            lastName={me.lastName}
                            image={me.imageUrl}
                            size={64}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: -8,
                                right: 0,
                            }}
                        >
                            <Upload
                                accept=".jpg,.png,.jpeg"
                                onChange={(e) => {
                                    if (!e.file || !e.file.size) return;
                                    // eslint-disable-next-line no-alert
                                    if (e.file.size > 614400) alert('Max file size 600KB');
                                    else {
                                        handleUpload(e);
                                    }
                                }}
                                beforeUpload={() => false}
                                // eslint-disable-next-line react/no-unstable-nested-components
                                itemRender={() => <div style={{ display: 'none' }} />}
                                listType="picture"
                                method="PATCH"
                            >
                                <Icon
                                    name="EditOutlined"
                                    backgroundColor={colors.background}
                                    color={colors.black}
                                    className="pointer"
                                    padding={8}
                                />
                            </Upload>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} className="flex-center-between">
                    <Col span={12}><Form.Item name="firstName"><InputField disabled placeholder="First Name" required /></Form.Item></Col>
                    <Col span={12}><Form.Item name="lastName"><InputField disabled placeholder="Last Name" required /></Form.Item></Col>
                </Row>
                {/* <Row>
                    <Button>
                        Save
                    </Button>
                </Row> */}
            </Form>
        </div>
    );
};

export default SettingsTemplate;
