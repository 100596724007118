import {
    Button,
    Col,
    Form,
    Row,
} from "antd";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../configureStore";
import FormItem from "../atoms/FormItem";
import Text from "../atoms/Text";
import RIAHeader from "../molecules/RIAHeader";

interface NoRIACodeParams {
}

interface FormParams {
    email: string;
    code: string;
}

const NoRIACode = ({
}: NoRIACodeParams) => {
    const envCode = process.env.REACT_APP_ENVIRONMENT;
    const [params, setParams] = useSearchParams();
    const [form] = Form.useForm<FormParams>();
    const language = useSelector<RootState, 'es' | 'en'>(({ auth: { lng } }) => lng);

    return (
        <div className="app-bg pa-2 vh100">
            <RIAHeader envCode={envCode} />
            <Row
                align="middle"
                justify="center"
                className="mt-8"
            >
                <Col>
                    <Text
                        customText={{
                            text: {
                                en: 'Please enter your invitation code and email to continue:',
                                es: 'Por favor ingrese su código de invitación y correo electrónico para continuar:',
                            },
                            tag: 'h2',
                        }}
                        className="mb-4"
                    />
                    <Form
                        form={form}
                        initialValues={{ code: params.get('code'), email: decodeURI(params.get('email')?.replace(' ', '+') || '') }}
                        onFinish={(values) => {
                            params.set('code', values.code);
                            params.set('email', encodeURI(values.email));
                            setParams(params);
                        }}
                    >
                        <FormItem
                            required
                            name="code"
                            label={language === 'en' ? 'Invitation Code' : 'Código de Invitación'}
                        />
                        <FormItem
                            required
                            name="email"
                            label={language === 'en' ? 'Email' : 'Correo Electrónico'}
                            htmlType="email"
                        />
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="w100"
                        >
                            {language === 'en' ? 'Confirm' : 'Confirmar'}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default NoRIACode;
