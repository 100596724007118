/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
    Form,
    Input,
    InputProps,
    Select,
    SelectProps,
} from "antd";
import { Rule } from "antd/es/form";
import InputMask from "react-input-mask";
import { useState } from "react";

function toCamelCase(input: string): string {
    // Split the input string into words
    const words = input.split(/[^a-zA-Z0-9]/);

    // Capitalize the first letter of each word (except the first one)
    const camelCasedWords = words.map((word, index) => {
        if (index === 0) {
            return word.toLowerCase(); // Lowercase the first word
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words to form the camelCased string
    return camelCasedWords.join('');
}

interface FormInputParams extends Omit<InputProps, 'value' | 'id' | 'name'> {
    type?: 'Input';
    name?: string;
    required?: boolean;
    label: string;
    extra?: string
    rules?: Rule[];
    index?: number;
    compact?: boolean;
    htmlType?: string;
}

interface FormSelectParams extends Omit<SelectProps, 'value' | 'id'> {
    type?: 'Select';
    name?: string;
    required?: boolean;
    label: string;
    extra?: string
    rules?: Rule[];
    index?: number;
    compact?: boolean;
    htmlType?: string;
}

interface FormMaskParams {
    type?: 'Mask';
    name?: string;
    required?: boolean;
    label: string;
    disabled?: boolean;
    extra?: string
    rules?: Rule[];
    mask: string;
    index?: number;
    compact?: boolean;
    htmlType?: string;
}

export type FormItemParams = FormInputParams | FormSelectParams | FormMaskParams;

const RequiredMark = () => <span style={{ lineHeight: '10px', color: 'red', paddingLeft: '1px' }}>*</span>;

const FormItem = ({
    name,
    label,
    type = 'Input',
    required = false,
    extra,
    rules = [],
    index,
    compact = false,
    htmlType,
    ...otherProps
}: FormItemParams) => {
    const [hover, setHover] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    if (required) rules.push({ required: true, message: `${label} is required` });

    return (
        <Form.Item
            className={`hsw-form-item ${compact ? '' : 'mb-8'} mt-2`}
            extra={extra}
        >
            {
                type === 'Select' ? (
                    <Form.Item
                        noStyle
                        name={index != null ? [index, name || toCamelCase(label)] : name || toCamelCase(label)}
                        rules={rules}
                    >
                        <Select
                            // @ts-ignore
                            onMouseEnter={() => { !otherProps.disabled && setHover(true); }}
                            onMouseLeave={() => { !otherProps.disabled && setHover(false); }}
                            onFocus={() => { !otherProps.disabled && setFocus(true); }}
                            onBlur={() => { !otherProps.disabled && setFocus(false); }}
                            // onChange={() => { !otherProps.disabled && setFocus(false); !otherProps.disabled && setHover(true); }}
                            // onDeselect={() => { !otherProps.disabled && setFocus(false); !otherProps.disabled && setHover(true); }}
                            // onDropdownVisibleChange={(e) => {
                            //     !otherProps.disabled && setFocus(e);
                            //     !otherProps.disabled && setHover(e);
                            // }}
                            placeholder={label}
                            {...otherProps}
                        />
                    </Form.Item>
                ) : type === 'Mask' ? (
                    <Form.Item
                        noStyle
                        name={index != null ? [index, name || toCamelCase(label)] : name || toCamelCase(label)}
                        rules={rules}
                    >
                        <InputMask
                            mask={(otherProps as any).mask}
                            maskChar="_"
                            onMouseEnter={() => { !otherProps.disabled && setHover(true); }}
                            onMouseLeave={() => { !otherProps.disabled && setHover(false); }}
                            onFocus={() => { !otherProps.disabled && setFocus(true); }}
                            onBlur={() => { !otherProps.disabled && setFocus(false); }}
                            placeholder={label}
                        >
                            {
                                // @ts-ignore
                                (inputProps) => (
                                    <Input
                                        {...inputProps}
                                        type={htmlType}
                                    />
                                )
                            }
                        </InputMask>
                    </Form.Item>
                ) : (
                    <Form.Item
                        noStyle
                        name={index != null ? [index, name || toCamelCase(label)] : name || toCamelCase(label)}
                        rules={rules}
                    >
                        <Input
                            onMouseOver={() => { !otherProps.disabled && setHover(true); }}
                            onMouseLeave={() => { !otherProps.disabled && setHover(false); }}
                            onFocus={() => { !otherProps.disabled && setFocus(true); }}
                            onBlur={() => { !otherProps.disabled && setFocus(false); }}
                            // @ts-ignore
                            placeholder={label}
                            type={htmlType}
                            {...otherProps}
                        />
                    </Form.Item>
                )
            }

            <label className={`hsw-label ${(hover || focus) ? 'hsw-label-hover' : ''} ${otherProps.disabled ? 'hsw-label-disabled' : ''}`}>
                {label}
                {required ? <RequiredMark /> : ''}
            </label>
        </Form.Item>
    );
};

export default FormItem;
