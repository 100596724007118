import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ConfigProvider } from "antd";
import { useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

import { AppDispatch, RootState } from "../../../configureStore";
import { Me, getMeByCode } from "../../../redux/reducer/auth";
import Intro from "./Intro";
import AgreeModal from "../../organisms/AgreeModal";
import useWindowDimensions from "../../../utils/hooks/useWindowDimensions";
import RIAHeader from "../../molecules/RIAHeader";
import NoRIACode from "../../organisms/NoRIACode";
import LoadOverlay from "../../atoms/LoadOverlay";
import { Configuration } from "../../../utils/config";
import Redirect from "./Redirect";

interface RIAParams {
}

const RIA = ({
}: RIAParams) => {
    const envCode = process.env.REACT_APP_ENVIRONMENT;
    const { isMobile } = useWindowDimensions();
    const [params] = useSearchParams();
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['auth/GET_ME'] || status.loading['auth/GET_ME_CODE']);
    const config = useSelector<RootState, Configuration>(({ auth: { configuration } }) => configuration);
    const language = useSelector<RootState, 'es' | 'en'>(({ auth: { lng } }) => lng);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        document.title = 'Halcyon RIA';
    }, []);

    useEffect(() => {
        const code = params.get('code');
        const email = params.get('email')?.replace(' ', '+') || null;
        if (code && email) dispatch(getMeByCode({ code, email }));
    }, [params]);

    const me = useSelector<RootState, Me>(({ auth }) => auth.me as Me);

    const [tnc, setTnc] = useState(false);
    const [terms, _setTerms] = useState(false);

    return (
        <>
            <Helmet>
                <title>Investment Advisory</title>
                <meta name="description" content="Connect with an investment advisor." />
                <meta property="og:title" content="Investment Advisory" />
                <meta property="og:description" content="Connect with an investment advisor." />
            </Helmet>
            {
                loading ? (
                    <LoadOverlay />
                ) : Object.keys(me ?? {}).length > 0 ? (
                    <ConfigProvider
                        theme={{
                            ...config?.app?.primaryColor && {
                                token: {
                                    colorPrimary: config?.app?.primaryColor,
                                },
                            },
                        }}
                    >
                        <div className="app-bg vh100" style={{ backgroundColor: config.app.backgroundColor }}>
                            <RIAHeader envCode={envCode} />
                            <div className="pa-2">
                                <AgreeModal
                                    tnc={tnc}
                                    setTnc={setTnc}
                                    email={params.get('email')?.replace(' ', '+') ?? null}
                                    code={params.get('code')}
                                />
                                {
                                    !me.riaTncAccepted
                                        ? (
                                            <div id="introduction">
                                                {!terms && <Intro />}
                                                <div className={isMobile ? "flex-center-center" : "flex-center-end"}>
                                                    <Button
                                                        type="primary"
                                                        className={isMobile ? "w80" : "w25"}
                                                        onClick={() => { setTnc(true); }}
                                                    >
                                                        {language === 'en' ? 'Book a Free Appointment' : 'Reserve una Cita Gratuita'}
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                        : <Redirect />
                                }
                            </div>
                        </div>
                    </ConfigProvider>
                ) : <NoRIACode />
            }
        </>
    );
};

export default RIA;
