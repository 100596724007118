/* eslint-disable max-len */
import { PublicClientApplication } from '@azure/msal-browser';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { loginRequest } from './authConfig';

export function jwtInterceptor(msalInstance: PublicClientApplication) {
  axios.interceptors.request.use(async (request: InternalAxiosRequestConfig) => {
    const account = msalInstance.getActiveAccount();
    request.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_OCP_APIM_SUB_KEY ?? '';
    request.headers['Content-Type'] = 'application/json';

    if (!account) return request;
    try {
      const accessTokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const { idToken } = accessTokenResponse;
      localStorage.setItem('ID_TOKEN', idToken);
    } catch (err) {
      msalInstance.logoutRedirect();
    }
    request.headers.Authorization = `Bearer ${localStorage.getItem('ID_TOKEN')}`;
    return request;
  });
}
