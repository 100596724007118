import { Dispatch } from 'react';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { AnyAction, configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/reducer';

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = Dispatch<AnyAction> & ThunkDispatch<RootState, null, AnyAction>

export default store;
