interface RedirectParams {
}

const Redirect = ({
}: RedirectParams) => {
    window.location.href = 'https://goplan101.com/account/coachcall/';

    return <div />;
};

export default Redirect;
