import { IText } from "./config";

/* eslint-disable no-shadow */
export enum TNCType {
    HEADER = "HEADER",
    BODY = "BODY",
}

export const tncRiaArray: { type: TNCType; text: IText }[] = [
    {
        type: TNCType.HEADER,
        text: 'Terms and Conditions',
    },
    {
        type: TNCType.BODY,
        text: "By clicking \"I consent\", I (or \"you\" or \"your\", as the case may be) acknowledge that I reviewed, understood, and consent to the following Terms and Conditions (\"Terms and Conditions\", \"Terms\")",
    },
    {
        type: TNCType.BODY,
        text: "Please note the provision of any investment advisory services between you and Wealth Enhancement Group LLC (\"WEG\") a registered investment advisor will be governed by a separate master services agreement. You should closely review the terms of that master services agreement before determining whether to engage WEG.",
    },
    {
        type: TNCType.HEADER,
        text: "Conditions of Use",
    },
    {
        type: TNCType.BODY,
        text: "By using this Website, you certify that you have read and reviewed these Terms and that you agree to be bound and comply with the Terms. If you do not want to be bound by the Terms, you are advised to leave the Website accordingly. We only grant use and access of this Website, its products, and its services to those who have accepted the Terms.",
    },
    {
        type: TNCType.HEADER,
        text: "Privacy Policy",
    },
    {
        type: TNCType.BODY,
        text: "Before you continue using our Websites, we advise you to read the WEG Privacy Policy, and the third party administrator (\"TPA\") Privacy Policy regarding its user data collection. It will help you better understand our practices.",
    },
    {
        type: TNCType.HEADER,
        text: "Information Sharing",
    },
    {
        type: TNCType.BODY,
        text: "I consent to be introduced to WEG for investment advisory services. The TPA has an integration with WEG that enables the TPA to share information with WEG and other service providers. You consent and direct us to share your information, including personal information (as described more fully below) to WEG. The TPA may share all data and information in its possession with WEG, including tax return information it obtained as a result of your consent and utilization of its services in connection with the income verification component of your loan through {{institutionName}}. \"Tax return information\" includes any data element obtained throughout the tax return preparation or tax data retrieval process, including but not limited to, tax return source documents, IRS tax transcript data, information derived from tax return elements, or other information provided in the preparation of tax return. You also consent that your spouse, if applicable and having filed your tax return jointly, consents to the sharing of information. Your consent will continue, unless the TPA is notified in writing at 25 Broad Street 2nd Floor, Red Bank, NJ 07701 to no longer disclose your information or you terminate your prior consent with the TPA that you provided in conjunction with your mortgage loan. Any information you provide to WEG, whether through the TPA or on your own, will be subject to their privacy practices and terms and conditions.",
    },
    {
        type: TNCType.HEADER,
        text: "Links to Other Sites",
    },
    {
        type: TNCType.BODY,
        text: "This Website may contain links to other independent third-party websites (\"Linked Sites\"). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for the content of such Linked Sites. Bear in mind that some of the Linked Sites are affiliate links and if you go through them to make a purchase, we will earn a commission. You will need to make your own independent judgment regarding your interaction with these Linked Sites.",
    },
    {
        type: TNCType.HEADER,
        text: "Revenue Sharing",
    },
    {
        type: TNCType.BODY,
        text: "WEG pays cash compensation to the TPA for aggregating and providing client lists (through the TPA platform or otherwise) and also in the event you become a client of WEG, each of which creates an incentive that results in a material conflict of interest. The TPA is not a client or an affiliate of WEG. WEG pays the TPA a fixed fee for your name upon your consent to these Terms and an additional 50% of all its fees that it receives in the event you become a client of WEG. The TPA then shares a portion of its fee, up to 50%, with the financial institution or business that introduced you to the TPA's platform. In your case, that is {{institutionName}}. Should you so desire, you are directed to inquire with the TPA as to the amount of compensation they have agreed to share with the business or financial institution that has introduced you to the TPA's platform.",
    },
    {
        type: TNCType.HEADER,
        text: "No Solicitation, Referral or Recommendation",
    },
    {
        type: TNCType.BODY,
        text: "You acknowledge and agree that you have not been solicited by {{institutionName}} or the TPA, or any of their respective employees, agents, or contractors (collectively, the \"Lender Parties\"), to become a client of WEG, nor have the Lender Parties offered or provided any financial advice or any endorsement of WEG to you in relation to the WEG's services or any other investment services or products nor have any Lender Parties recommended or endorsed any specific financial advisor, financial products, services or strategies to you.",
    },
    {
        type: TNCType.HEADER,
        text: "Disclosures and Disclaimer",
    },
    {
        type: TNCType.BODY,
        text: "You acknowledge that you have received the {{institutionName}} Registered Investment Advisor Program Disclosures addressing Compensation and Suitability and a Disclaimer of Endorsement (a copy of which can be found at this link: {{csdeLink}}).",
    },
    {
        type: TNCType.BODY,
        text: "You acknowledge and agree that this communication is not to be construed as a solicitation, offer, or recommendation, to buy or sell any security. Investment management and advisory services are provided by Wealth Enhancement Group LLC an SEC registered investment adviser.",
    },
    {
        type: TNCType.HEADER,
        text: "Independent Nature",
    },
    {
        type: TNCType.BODY,
        text: "You acknowledge and understand that the transactions contemplated by this Agreement have no bearing whatsoever on the decisioning with regard to the loan services provided by {{institutionName}}. Your decision to use or not to use the services of the WEG does not influence any aspect of your loan process or application.",
    },
    {
        type: TNCType.HEADER,
        text: "Applicable Law",
    },
    {
        type: TNCType.BODY,
        text: "By visiting this Website, you agree that the laws of the State of New Jersey without regard to principles of conflict laws, will govern these Terms and Conditions, or any dispute of any sort that might come between Us and you, or its business partners and associates.",
    },
    {
        type: TNCType.HEADER,
        text: "Disputes",
    },
    {
        type: TNCType.BODY,
        text: "Any dispute related in any way to your visit to this Website or your consent or rejection of these Terms shall be conducted under the auspices of JAMS pursuant to its Arbitration Rules and Procedures and the arbitration shall occur in Monmouth County, New Jersey.",
    },
    {
        type: TNCType.HEADER,
        text: "Indemnification",
    },
    {
        type: TNCType.BODY,
        text: "You agree to indemnify the Lender Parties and their affiliates and hold us harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.",
    },
    {
        type: TNCType.HEADER,
        text: "Limitation on Liability",
    },
    {
        type: TNCType.BODY,
        text: "The Lender Parties, including their affiliates, are not liable for any damages that may occur to you as a result of your misuse of our Website.",
    },
    {
        type: TNCType.BODY,
        text: "WEG and the TPA reserve the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between TPA, WEG and You, and this supersedes and replaces all prior agreements regarding the use of this Website.",
    },
];
