/* eslint-disable no-cond-assign */
import { HTMLProps, useMemo } from "react";
import { useSelector } from "react-redux";
import { CustomText, IText, customTextStyle } from "../../utils/config";
import { RootState } from "../../configureStore";

interface TextParams extends HTMLProps<HTMLHeadingElement | HTMLParagraphElement> {
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
    customText?: CustomText;
    defaultText?: IText;
    checkReplace?: boolean;
    replaceObject?: { [key: string]: string };
}

const Text = ({
    tag = 'p',
    customText,
    style,
    defaultText,
    checkReplace = false,
    replaceObject = {},
    ...otherProps
}: TextParams) => {
    const textColor = useSelector<RootState, string | undefined>(({ auth: { configuration: { app } } }) => app.color);
    const colors = useSelector<RootState, { header?: string; body?: string; } | undefined>(({ auth: { configuration: { app } } }) => app.textColor);
    const language = useSelector<RootState, 'es' | 'en'>(({ auth: { lng } }) => lng);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!customText && !defaultText) return <></>;
    const Tag = (customText?.tag ?? `${tag}`) as keyof JSX.IntrinsicElements;

    const textToUse = useMemo(() => {
        const toUse = customText?.text ? typeof customText.text === 'string' ? customText.text : customText.text[language] : typeof defaultText === 'string' ? defaultText : defaultText?.[language];
        if (checkReplace && toUse) {
            const matches: string[] = [];
            // eslint-disable-next-line prefer-regex-literals
            const regex = new RegExp(/\{\{([^}]+)\}\}/, 'g');
            let match: RegExpExecArray | null;

            while ((match = regex.exec(toUse)) !== null) {
                matches.push(match[1]);
            }
            let toRet = toUse;
            matches.forEach((mtch) => {
                toRet = toRet.replaceAll(`{{${mtch}}}`, replaceObject[mtch] || '');
            });
            return toRet;
        }
        return toUse;
    }, [checkReplace]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Tag {...otherProps} style={{ ...customTextStyle(customText, colors ? tag.startsWith('h') ? colors.header || textColor : colors.body || textColor : textColor), ...style }}>{textToUse}</Tag>;
};

export default Text;
